/* 🔥 NAVBAR CONTAINER */
.curved-navbar {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 75px; /* Adjusted for consistency */
  background: #e1ebfa;
  /* border-radius: 50px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease-in-out;
  /* margin-top: 20px; */

}

/* 🔥 NAVBAR INNER CONTAINER */
.nav-container {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: none;
  min-height: auto;
  margin-left: 0;
  margin-right: 0;
  padding: 0 15px;
  display: flex;
  /* height: 5vh; */
}

/* 🔥 FIX LOGO SIZING */
.logo-link {
  display: flex;
  align-items: center;
}

.logo {
  width: 200px;
  max-width: 100%;
  height: auto;
  padding-top: 10px;
  padding-left: 20px;
}

/* 🔥 NAVBAR LINKS */
.nav-links ul {
  list-style: none;
  display: flex;
  gap: 10px;
  padding: 0;
  margin: 0;
  cursor: pointer;

}

.nav-links li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 18px;
  padding: 10px 15px;
  transition: color 0.3s ease-in-out;
}

.nav-links li a:hover {
  color: #5aa9e6;
}

/* 🔥 BUTTON STYLING */
.login-btn {
  background: linear-gradient(90deg, #5aa9e6, #3a86ff);
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 8px 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.login-btn:hover {
  background: linear-gradient(90deg, #3a86ff, #5aa9e6);
  transform: scale(1.05);
}

.login-btn:active {
  transform: scale(0.95);
}

/* 🔥 MOBILE MENU BUTTON */
.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

/* 🔥 RESPONSIVE FIXES */
@media screen and (max-width: 900px) {
  .curved-navbar {
      width: 100%;
      height: auto;
      /* border-radius: 50px; */
      /* padding: 10px 15px; */
  }

  .logo {
      width: 150px;
      padding-left: 0px;
  }

  .nav-links {
      display: none;
      position: absolute;
      top: 60px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      background: rgba(5, 5, 5, 0.7);
      border-radius: 10px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.6);
      padding: 10px;
      flex-direction: column;
      align-items: center;
      justify-content: start;
  }

  .nav-links.open {
      display: flex;
      justify-items: left;
  }

  .nav-links ul {
      flex-direction: column;
      width: 100%;
      text-align: center;
  }

  .nav-links li {
      padding: 0px 0;
      width: 100%;
      border-bottom: 1px solid rgba(240, 230, 230, 0.3); /* 🔥 Adds line */
      align-items: center;
  }

  .nav-links li:last-child {
    border-bottom: none;
}
  .nav-links li a {
      font-size: 20px;
      color: white !important;
      text-decoration: none;
      display: block;
      width: 100%;
      padding: 10px 0;
  }

  .dropdown-toggle {
    background: transparent;
    border: none;
    color: white !important;
    font-size: 16px;
    cursor: pointer;
    /* padding: 10px; */
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 50% !important;
    /* background: white; */
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    width: auto !important;
    display: flex;
    flex-direction: column;
    gap: 0px !important;
  }
  .d-menu {
    background: rgba(5, 5, 5, 0.7) !important;
    /* color: black !important; */
    height: auto;
  }
  .login-btn {
      font-size: 16px;
      border-radius: 10px;
      padding: 5px 8px;
  }

  .menu-toggle {
      display: block;
  }
}

/* 🔥 MOBILE VIEW */
@media screen and (max-width: 768px) {
  .curved-navbar {
      flex-direction: column;
      height: auto;
      /* padding: 10px; */
  }

  .logo {
      width: 120px;
  }

  .nav-links {
      display: none;
      flex-direction: column;
      width: 100%;
      text-align: center;
  }

  .nav-links.open {
      display: flex;
  }
}


/* 🔥 Hide dropdown in small screens */
@media screen and (max-width: 768px) {
  .desktop-only {
      display: none; /* ✅ Hides "Why Us?" dropdown in mobile */
  }

  .mobile-only {
      display: block; /* ✅ Shows separate links */
  }
}

/* 🔥 Dropdown styles (visible only on big screens) */
.why-us {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown-toggle {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #333;
  font-weight: bold;
  font-size: 18px;
  /* padding: 10px 15px; */
  transition: color 0.3s ease-in-out;
  /* padding: 10px; */
}

.dropdown-toggle:hover {
    color: #5aa9e6;
  
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  /* padding: 10px; */
  z-index: 1000;
  list-style: none;
  width: max-content !important;
  display: flex;
  flex-direction: column;
  gap: 0px !important;
  /* width: 180px; */
}

.why-us:hover .dropdown-menu,
.dropdown-menu.open {
  display: block; /* ✅ Shows dropdown only when clicked */
}

.dropdown-menu li {
  padding: 8px;
  cursor: pointer;
  display: block;
  padding: 10px;
  gap: 10px;
  color: #134084 !important;
  text-decoration: none;
  transition: 0.3s;
  background-color: transparent;
}

.dropdown-menu li:hover {
  background: #f0f0f0;
}
