.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.section {
  width: 100% !important;
  /* display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 100px; */
  /* box-sizing: border-box; */
  /* border-bottom: 2px solid #ddd; */
}

/* @media screen and (max-width: 1700px) {

  .section {
    width: 100% !important;
    height: 100vh !important;

  }

} */

/* .section {
  width: 100%;  
  height: 100vh; 
  scroll-margin-top: 150px; 
  box-sizing: border-box;
  border-bottom: 2px solid #ddd;

} */




/* 🔥 Curved Navbar */
/* .curved-navbar {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 100px;
  background: #e1ebfa; 
  border-radius: 50px; 
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  backdrop-filter: blur(10px); 
  transition: all 0.3s ease-in-out;
  margin-top: 20px;
}

.curved-navbar:hover {
  background: rgba(255, 255, 255, 1);
}

.nav-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 20px;
}

.logo {
  justify-content: center;
  align-items: start;
  height: 100px;
  display: flex;
  padding-top: 13px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 30px;
  padding: 0;
  margin: 0;
}

.nav-links li {
  display: inline-block;
}

.nav-links li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 18px;
  padding: 10px 15px;
  transition: color 0.3s ease-in-out;
}

.nav-links li a:hover {
  color: #5aa9e6; 
} */

/* 🔥 Ensure Full-Screen Section */
/* 🔥 Full-Screen Section */


