.grow-business {
    text-align: center;
    padding: 80px 10%;
    /* background: linear-gradient(to right, #e0e2e7, #a6bae0); */
    color: #134084;
}

.grow-heading {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 50px;
}

.grow-subtext {
    font-size: 18px;
    color: #555;
    margin-bottom: 40px;
}

/* 🔥 Benefit Grid */
.benefit-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    justify-content: center;
}

/* 🔥 Benefit Item */
.benefit-item {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    mix-blend-mode: multiply; /* Removes white background */
    background-color: transparent;
}

.benefit-item:hover {
    transform: scale(1.05);
}

.benefit-icon {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
}

.benefit-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
}

.benefit-content {
    font-size: 16px;
    color: #555;
}

/* 🔥 Call to Action */
.cta-section {
    margin-top: 40px;
}

.cta-button {
    padding: 12px 25px;
    background: #ff6f61;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    transition: 0.3s;
}

.cta-button:hover {
    background: #e65042;
}

/* 🔥 Responsive Design */
@media screen and (max-width: 1024px) {
    .benefit-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .benefit-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}
