/* 🔥 Main Section */
.animated-bg-partner {
    display: flex;
    margin: 0;
    background-color: white;
    padding: 50px 50px;
}

/* 🔥 Section Wrapper */
.section-start {
    display: flex;
    width: 100%;
}

/* 🔥 Grid Layout */
.benefit-section {
    display: grid;
    grid-template-columns: 0.8fr 1fr; /* Keeps balance between text and image */
    gap: 40px; /* Adds space for better readability */
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

/* 🔥 Left Content */
.lending-partners {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 5%;
    margin-bottom: 5%;
    /* padding-left: 8%; */
    max-width: 100%; /* Ensures good text width */
}

/* 🔥 Left Side Content */
.lp-text-content {
    max-width: 100%;
}

/* 🔥 Heading */
.lp-heading {
    font-size: 50px;
    font-weight: 900;
    color: #134084;
    margin-bottom: 30px;
}

/* 🔥 Features Container */
.lp-features {
    display: flex;
    flex-direction: column;
    gap: 25px; /* Ensures even spacing between features */
}

/* 🔥 Feature Box */
.lp-feature {
    display: flex;
    align-items: center;
    gap: 20px; /* Keeps icons and text aligned */
    mix-blend-mode: multiply; /* Removes white background */
    background-color: transparent;
}

/* 🔥 Feature Icon */
.lp-icon {
    width: 55px;
    height: 55px;
    flex-shrink: 0; /* Prevents icons from shrinking */
}

/* 🔥 Feature Text Alignment */
.lp-feature-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* 🔥 Feature Title */
.lp-feature-title {
    font-size: 24px;
    font-weight: bold;
    color: #4c4b4b;
    margin-bottom: 5px;
}

/* 🔥 Feature Text */
.lp-feature-text {
    font-size: 16px;
    color: #666;
}

/* 🔥 Right Image */
.lp-image {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    max-width: 700px; /* Ensures a large, balanced image */
    margin: 0 auto;
}

.lp-image img {
    width: 100%;
    max-width: 700px;
    height: auto;
}

/* 🔥 Responsive Fixes */
@media screen and (max-width: 1400px) {
    .lending-partners {
        /* padding-left: 6%; */
        max-width: 600px;
    }

    .lp-image {
        max-width: 650px;
    }
}

@media screen and (max-width: 1200px) {
    .lending-partners {
        max-width: 550px;
    }

    .lp-image {
        max-width: 600px;
    }
}

/* 🔥 Medium Screens (Tablets) */
@media screen and (max-width: 1024px) {
    .benefit-section {
        grid-template-columns: 1fr;
        align-items: center;
        text-align: center;
        gap: 0px; /* Adds space for better readability */

    }

    .lending-partners {
        /* padding: 0 10%; */
        max-width: 600px;
        margin: 5% auto;
        align-items: center;
    }

    .lp-heading {
        font-size: 40px;
        margin-bottom: 25px;
    }

    .lp-feature {
        flex-direction: row;
        justify-content: flex-start;
        text-align: left;
        /* gap: 15px; */
        align-items: center;

    }

    .lp-feature-title,
    .lp-feature-text {
        text-align: left;
    }

    .lp-image {
        width: 100%;
        max-width: 600px;
        margin-top: 10px;
    }
    .lp-image-borrower img {
        width: 100%;
        max-width: 600px;
    }
}

/* 🔥 Small Screens */
@media screen and (max-width: 768px) {
    .lp-heading {
        font-size: 34px;
    }

    .lp-feature {
        flex-direction: row;
        justify-content: flex-start;
        text-align: left;
        /* gap: 12px; */
    }

    .lp-feature-title {
        font-size: 18px;
    }

    .lp-feature-text {
        font-size: 15px;
    }

    .lp-image {
        width: 100%;
        max-width: 500px;
        /* margin-top: 10px; */
    }

    .lp-image img {
        width: 100%;
        max-width: 500px;
    }
}

/* 🔥 Extra Small Screens */
@media screen and (max-width: 480px) {
    .lp-heading {
        font-size: 28px;
    }

    .lp-feature {
        flex-direction: row; /* Ensures icon + text alignment */
        /* gap: 10px; */
        align-items: center;
        text-align: left;

    }

    .lp-feature-title,
    .lp-feature-text {
        text-align: left;
    }

    /* .lp-icon {
        width: 45px;
        height: 45px;
    } */

    .lp-image {
        width: 100%;
        max-width: 450px;
        /* margin-top: 5px; */
    }

    .lp-image img {
        width: 100%;
        max-width: 450px;
    }
}
