/* 🔥 Default (For Large Screens) */
.animated-bg {
  width: 100%;
  height: 700px !important;
  /* height: 100vh; Full viewport height */
  background: linear-gradient(to bottom, #71c3f7, #f6f6f6);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 3% 3% 3% ;
  
}

video {
  height:450px ;
  width: auto;
  object-fit: contain;
  overflow-clip-margin: content-box;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);

  /* overflow: clip; */
}
/* 🔥 Content Layout */
.content-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

/* 🔥 Left & Right Sections */
.left-content {
  width: 50%;
  text-align: left;
}

.left-content h1{
  color: #1f3f77;
  font-size:50px;
  max-width: 600px;
}

.left-content p.one{
  color:#1f7774;
  font-size:30px
}

.left-content p.two{
  color: #1f3f77;
  font-size:40px;
  font-weight: bold;
}

.right-content {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.right-content img {
  max-width: 100%;
  height: 400px;
  /* height: auto; */
  border-radius: 10px;
}

/* 🔥 Responsive for 1700px */
@media screen and (max-width: 1700px) {
  .animated-bg {
    padding: 50px;

  }

  video {
    height:400px ;
    object-fit: contain;
    overflow-clip-margin: content-box;
    /* overflow: clip; */
  }
}

@media screen and (max-width: 1400px  ) {
  .animated-bg {
    padding: 50px 50px 0px 50px ;

  }

  video {
    height:300px ;
    object-fit: contain;
    overflow-clip-margin: content-box;
    /* overflow: clip; */
  }
}

@media screen and (max-width: 850px  ) {
   video {
    height:300px !important ;
    object-fit: contain;
    overflow-clip-margin: content-box;
    /* overflow: clip; */
  }
}

@media screen and (max-width: 650px  ) {
  video {
    height:320px !important ;
   object-fit: contain;
   overflow-clip-margin: content-box;
   /* overflow: clip; */
 }
}

@media screen and (max-width: 550px  ) {

  .animated-bg {
    padding: 50px 50px 0px 50px ;
    width: 100% !important;
  }
  video {
    height:250px !important ;
   object-fit: contain;
   overflow-clip-margin: content-box;
   /* overflow: clip; */
 }
}

@media screen and (max-width: 450px  ) {
  video {
    height:200px !important ;
   object-fit: contain;
   overflow-clip-margin: content-box;
   /* overflow: clip; */
 }

 .left-content h1{
  color: #1f3f77;
  font-size:30px !important;
  max-width: 100px;
}

.left-content p.one{
  color:#1f7774;
  font-size:20px !important
}

.left-content p.two{
  color: #1f3f77;
  font-size:25px !important;
  font-weight: bold;
}
}


/* 🔥 Responsive for 1270px */
@media screen and (max-width: 1020px) {
  .animated-bg {
    padding: 90px;
    height: auto !important;
  }

  video {
    height:400px ;
    object-fit: contain;
    /* overflow-clip-margin: content-box; */
    /* overflow: clip; */
  }

  .content-container {
    flex-direction: column; /* ✅ Stacks content */
    text-align: center;
    gap: 60px;
    
  }

  .left-content,
  .right-content {
    width: 100%; 
    text-align: center;
  }

  .left-content h1{
    color: #1f3f77;
    font-size:35px;
    max-width: 100%;
  }
  
  .left-content p.one{
    color:#1f7774;
    font-size:20px
  }
  
  .left-content p.two{
    color: #1f3f77;
    font-size:25px;
    font-weight: bold;
  }
  .left-content {
    order: 1; /* ✅ Moves text below */
  }

  .right-content {
    /* ✅ Moves image above */
  }

  .right-content {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    order: 0; 
  }
  
}
