/* 🔥 Footer Styling */
.footer {
  background-color: #134084;
  color: white;
  padding: 30px 0;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

/* 🔥 Footer Container */
.footer-container {
  max-width: 800px;
  margin: auto;
  padding: 0 20px;
}

/* 🔥 Contact Info */
.footer-section h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.footer-section p {
  font-size: 14px;
  line-height: 1.5;
  color: #ffffffcc;
  margin: 5px 0;
}

.footer-section a {
  color: #ffffffcc;
  text-decoration: none;
}

.footer-section a:hover {
  color: white;
}

/* 🔥 Footer Bottom */
.footer-bottom {
  margin-top: 15px;
  padding: 10px 0;
  background: #0f356e;
  font-size: 14px;
}

/* 🔥 Social Icons */
.social-icons {
  margin-top: 8px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-icons a {
  display: inline-block;
}

.social-icons img {
  width: 20px;
  height: 20px;
  filter: invert(100%);
  transition: opacity 0.3s ease-in-out;
}

.social-icons img:hover {
  opacity: 0.7;
}

/* 🔥 Responsive */
@media (max-width: 600px) {
  .footer-container {
      text-align: center;
  }
}
