/* 🔥 Contact Section */
.contact-section {
    padding: 80px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
}

.p-5{
padding: 0px !important;
}
.row{
    row-gap: 30px;
    width: 100%;
}
/* 🔥 Container */
.contact-container {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    /* border-radius: 15px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); */
}

.first{
    text-align: left;
     max-width: 500px;
}
.second{
    margin-left: 20px;
    background: #ffffff;
    padding: 40px;
    border-radius: 15px;
    
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
/* 🔥 Form Styling */
form {
    width: 100%;
}

/* 🔥 Input Fields */
input, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
}

/* 🔥 Focus Effect */
input:focus, textarea:focus {
    border-color: #134084;
    outline: none;
    box-shadow: 0px 0px 5px rgba(19, 64, 132, 0.3);
}

/* 🔥 Labels */
label {
    font-weight: bold;
    color: #134084;
    margin-bottom: 5px;
    display: block;
    font-family: sans-serif;
}

/* 🔥 Button */
.btn-primary {
    background: #134084;
    color: white;
    font-size: 18px;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: block;
    width: 100%;
    text-align: center;
}

.btn-primary:hover {
    background: #0f3060;
}

/* 🔥 Contact Row */
.contact-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

/* 🔥 Columns */
.col {
    flex: 1;
}

/* 🔥 Success Message */
.success-card {
    background: #e6f8e6;
    color: #2e7d32;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    font-weight: bold;
}

/* 🔥 Responsive */
@media screen and (max-width: 1000px) {

    .contact-section {
        padding: 70px 40px;
    }
    .contact-container {
        flex-direction: column;
        align-items: center;
        gap: 30px; /* Reduced gap for compact layout */
        /* padding: 0 20px; */
        /* padding: 30px; */
    }

    .first {
        text-align: center;
        width: 100%;
        max-width:700px;
    }

    /* 🔹 Ensure form fits within screen */
    .second {
        width: 90%; /* Adjust width for small screens */
        padding: 30px;
        margin: 0px;
    }
    .contact-row {
        flex-direction: column;
        gap: 15px;
    }

    .col {
        width: 100%; /* Ensure inputs take full width */
    }

    input, textarea {
        font-size: 14px;
        padding: 10px;
    }

    /* 🔹 Button adjustments */
    .btn-primary {
        font-size: 16px;
    }

    .second{
        width: 80%;
        padding: 50px;
        margin: 20px;
    }
    .btn-primary {
        font-size: 16px;
    }
}
